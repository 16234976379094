<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h2 class="my-2 font-semibold text-blue-800 text-2xl">
        Override Code Generator
      </h2>
      <div class="flex items-center justify-between">
        <div class="my-10 flex w-full justify-end items-end">
          <button
            @click="
              $router.push({
                name: 'NewTerminalOverrideCode',
              })
            "
            class="block uppercase shadow bg-blue-600  leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
          >
            New Code
          </button>
          <!-- <button
              @click="downloadCsv"
              class="
                      block
                      uppercase
                      shadow
                      ml-3
                      bg-green-800
                      hover:bg-blue-700
                      focus:shadow-outline
                      focus:outline-none
                      text-white text-xs
                      py-3
                      px-8
                      rounded
                    "
            >
              Download CSv
            </button> -->
        </div>
      </div>

      <div class="overflow-scroll pb-6" style="height:600px">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <tr>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                First Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Last Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Email
              </th>

              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Code
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(terminal, i) in filterTerminals"
              :key="i"
              class="hover:bg-gray-300"
            >
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.first_name }}
              </td>
              <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
                {{ terminal.last_name || "N/A" | truncate(30, "...") }}
              </td>
              <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
                {{ terminal.email || "N/A" | truncate(30, "...") }}
              </td>
              <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
                {{ "OC" + terminal.code || "N/A" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="
                      
                      uppercase
                      shadow
                      ml-3
                      hover:bg-blue-600
                      
                      focus:shadow-outline
                      focus:outline-none
                      text-xs
                      font-bold
                      py-3
                      px-8
                      rounded
                    "
          :class="currentPage === 1 ? 'bg-blue-200' : 'bg-blue-900 text-white '"
          :data-currPage="currentPage"
        >
          &laquo; Prev
        </button>
        <button
          @click="nextPage(currentPage)"
          class="
                      float-right
                      uppercase
                      shadow
                      ml-3
                      hover:bg-blue-600
                      bg-blue-900
                      focus:shadow-outline
                      focus:outline-none
                      text-white text-xs
                      font-bold
                      py-3
                      px-8
                      rounded
                    "
          v-if="filterTerminals.length > 99"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { json2excel } from "js2excel";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      isLoading: false,
      search: "",
      moment,
      currentPage: 1,
      isDisabled: true,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_OVERRIDE_LISTS"]),
    filterTerminals() {
      if (this.GET_OVERRIDE_LISTS.data.code_details !== null) {
        return this.GET_OVERRIDE_LISTS.data.code_details.filter(
          (terminal) =>
            terminal.first_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            terminal.last_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            terminal.email.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return [];
      }
    },
  },
  async created() {
    this.fetchTerminalRequests();
  },
  methods: {
    ...mapActions(["FETCH_OVERRIDE_LIST"]),
    async fetchTerminalRequests() {
      try {
        this.isLoading = true;
        let res = await this.$store.dispatch("FETCH_OVERRIDE_LIST");
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async searchBtn() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch(
          "SEARCH_ALL_TERMINALS_USING_TERMINAL_ID_OR_SERIAL_NUMBER",
          {
            search: this.search,
          }
        );
        if (res) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      try {
        let res = await this.$store.dispatch("FETCH_PAGINATED_TERMINAL", {
          page: currPage - 1,
        });
        if (res.status) {
          this.isLoading = false;
          this.currentPage -= 1;
        }
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      try {
        let res = await this.$store.dispatch("FETCH_PAGINATED_TERMINAL", {
          page: currPage + 1,
        });
        if (res.status) {
          this.isLoading = false;
          this.currentPage += 1;
        }
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async downloadCsv() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("DOWNLOAD_ALL_TERMINALS_IN_CSV");
        if (res.status) {
          window.open(res.data, "_blank");
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
